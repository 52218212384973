import React, { Component } from "react";
import { MessageDialog } from "../common/Dialog";
import { NavigationButton } from "../common/Buttons";
import DOMPurify from "dompurify";

class HelpMessageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      messages: [],
      confirmedIds: [],
      size: 0,
      index: 0,
    };
  }

  componentDidMount() {
    let url = this.props.session.getModules().help.links.helpMessages;
    url += "?urlFilter=" + encodeURIComponent(window.location.href);
    this.props.session.backendGet(url, (response) => {
      this.setState({
        messages: response ? response : [],
        size: response ? response.length : 0,
        show: response ? response.length > 0 : false,
      });
    });
  }

  onClose = () => {
    if (this.state.confirmedIds.length > 0) {
      let url = this.props.session.getModules().help.links.confirmHelpMessages;
      url += "?messageIds=" + this.state.confirmedIds.join(",");
      this.props.session.backendPost(url, null, () => this.onCloseDialog());
    } else {
      this.onCloseDialog();
    }
  };

  onCloseDialog = () => {
    this.setState({
      show: false,
      messages: [],
      confirmedIds: [],
      size: 0,
      index: 0,
    });
  };

  onCheckChange = (changeEvent, currentId) => {
    let confirmedIds = this.state.confirmedIds;
    if (changeEvent.target.checked && !confirmedIds.includes(currentId)) {
      confirmedIds.push(currentId);
    } else if (
      !changeEvent.target.checked &&
      confirmedIds.includes(currentId)
    ) {
      let index = confirmedIds.indexOf(currentId);
      confirmedIds.splice(index, 1);
    }
    this.setState({
      confirmedIds: confirmedIds,
    });
  };

  onPrevious = () => {
    if (this.state.index < 1) {
      return;
    }
    let index = this.state.index - 1;
    this.setState({ index: index });
  };

  onNext = () => {
    if (this.state.index >= this.state.size - 1) {
      return;
    }
    let index = this.state.index + 1;
    this.setState({ index: index });
  };

  getFooter = () => {
    if (this.state.size < 1) {
      return;
    }
    let footer = [];
    if (this.state.index > 0) {
      footer.push([
        <NavigationButton
          key="previous"
          onClick={() => this.onPrevious()}
          text="Previous"
          type={"button"}
        />,
      ]);
    }
    if (this.state.index < this.state.size - 1) {
      footer.push([
        <NavigationButton
          key="next"
          onClick={() => this.onNext()}
          text="Next"
          type={"button"}
        />,
      ]);
    }
    footer.push([
      <NavigationButton
        key="close"
        onClick={() => this.onClose()}
        text="Close"
        type={"button"}
      />,
    ]);
    return <div>{footer}</div>;
  };

  getTitle = () => {
    let currentMessage = this.state.messages[this.state.index];
    let title = currentMessage.title;
    if (this.state.size > 1) {
      title +=
        " (" + (this.state.index + 1) + "/" + this.state.size + " messages)";
    }
    return title;
  };

  getBody = () => {
    let currentMessage = this.state.messages[this.state.index];
    let checkbox = null;
    if (!currentMessage.forceShown) {
      let input = (
        <input
          type="checkbox"
          onChange={(e) => this.onCheckChange(e, currentMessage.id)}
          checked={this.state.confirmedIds.includes(currentMessage.id)}
        />
      );
      checkbox = (
        <label className="check-label">
          {input}
          &nbsp;Do not show the message again
        </label>
      );
    }

    return (
      <div style={{ height: "100%" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(currentMessage.body),
          }}
          style={{ minHeight: "calc(100% - 40px)" }}
        ></div>
        {checkbox}
      </div>
    );
  };

  render() {
    if (this.state.size < 1) {
      return;
    }

    return (
      <MessageDialog
        title={this.getTitle()}
        body={this.getBody()}
        show={this.state.show}
        width="md"
        height="md"
        footer={this.getFooter()}
      />
    );
  }
}

export { HelpMessageDialog };
