import React, { Component } from "react";
import styled from "styled-components";
import { darken } from "polished";
import { Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Colors from "./theme/Colors";
import Theme from "./theme/Theme";
import Icon from "./theme/Icons";
import { InfoButton } from "./Buttons";
import { DetailsDialog } from "./Dialog";

const ContactAreaStyle = styled.div`
  & {
    position: absolute;
    bottom: 0px;
    height: ${Theme.contactAreaHeight};
    width: 100%;
  }

  button {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  button.status-0 {
    display: none;
  }

  button.status-1 {
    background-color: ${Colors.warning};
  }

  button.status-2 {
    background-color: ${Colors.failed};
  }
`;

const CustomInfoButton = styled(InfoButton)`
  && {
    &:hover {
      background: ${(props) =>
        darken(0.1, props.pageTipStatus === 2 ? "grey" : "orange")};
    }

    &.active {
      background: ${Colors.tertiary};
    }
  }
`;

const CardStyle = styled.div`
  & {
    background-color: ${darken(0.05, Colors.tertiary)};
    text-align: center;
    padding: 25px 25px 45px 25px;
  }

  .name {
    color: ${Colors.white};
    font-weight: 600;
    font-size: 16px;
  }

  .title {
    font-size: 12px;
    color: ${Colors.secondary};
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .phone,
  .email {
    font-size: 10px;
    color: ${Colors.white};
    font-weight: 300;
    a:link,
    a:visited {
      color: ${Colors.white};
    }
  }

  .description {
    font-size: 10px;
    color: ${Colors.white};
    font-weight: 300;
    margin-top: 8px;
  }

  .text {
    padding-top: 105px;
  }

  img {
    border-radius: 88px;
    width: 88px;
    height: 88px;
    border: 3px solid ${Colors.primary};
    margin-bottom: 15px;
  }

  img.default-img {
    border: 2px solid ${Colors.white};
    background-color: ${Colors.white};
  }

  .images {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .images img {
    position: absolute;
    cursor: pointer;
    transition: all 0.3s ease-out;
    left: 0px;
  }

  .images img:nth-child(1) {
    z-index: 999;
  }
  .images img:nth-child(2) {
    z-index: 998;
    transform: translate(4%, -4%);
  }
  .images img:nth-child(3) {
    z-index: 997;
    transform: translate(8%, -8%);
  }
  .images:hover img:nth-child(1) {
    transform: scale(1.02);
  }
  .images:hover img:nth-child(2) {
    transform: scale(1.02) translate(6%, -6%);
  }
  .images:hover img:nth-child(3) {
    transform: scale(1.02) translate(12%, -12%);
  }
`;

const StyledContainer = styled(Container)`
  max-height: 70vh;

  .card {
    float: left;
    display: grid;
    margin: 15px;
    width: 240px;
    height: 275px;
  }

  .card .phone,
  .card .email {
    font-size: 12px;
  }

  p {
    margin-left: 15px;
    font-size: 16px;
  }

  .internal-team-prefix div {
    margin-bottom: 10px;
  }

  .internal-team-bullet div.col-11 {
    margin-top: 8px;
  }

  .internal-team-bullet,
  .internal-team-no-bullet {
    margin-bottom: 0px !important;
  }

  .internal-team-no-bullet {
    margin-top: 5px !important;
  }
`;

class ContactArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showExternalTeamDialog: false,
      showInternalTeamDialog: false,
    };
  }

  showDialog = () => {
    if (this.props.session.hasRole("SYS_ADMIN")) {
      this.setState({ showInternalTeamDialog: true });
    } else {
      this.setState({ showExternalTeamDialog: true });
    }
  };

  render() {
    return (
      <ContactAreaStyle>
        <ContactCard
          singleContact={false}
          photoBody={this.props.team.photoBody}
          name={this.props.team.name}
          title={this.props.team.title}
          phone={this.props.team.phone}
          email={this.props.team.email}
          onClick={this.showDialog}
        />
        <CustomInfoButton
          onClick={this.props.onPageTipClick}
          icon={"info"}
          iconsize={15}
          pageTipStatus={this.props.pageTipStatus}
          className={"status-" + this.props.pageTipStatus}
        />
        <ExternalTeamDialog
          show={this.state.showExternalTeamDialog}
          team={this.props.team}
          responsibles={this.props.responsibles}
          onClose={() => this.setState({ showExternalTeamDialog: false })}
        />
        <InternalTeamDialog
          show={this.state.showInternalTeamDialog}
          team={this.props.team}
          onClose={() => this.setState({ showInternalTeamDialog: false })}
        />
      </ContactAreaStyle>
    );
  }
}

class ContactCard extends Component {
  getImage(photoBody) {
    if (photoBody != null) {
      return <img src={"data:image/png;base64," + photoBody} alt={""} />;
    } else {
      return (
        <img
          src={require("./img/logo-round.png")}
          alt={""}
          className="default-img"
        />
      );
    }
  }

  render() {
    if (this.props.singleContact) {
      return (
        <CardStyle>
          {this.getImage(this.props.photoBody)}
          <div className="name">{this.props.name}</div>
          <div className="title">{this.props.title}</div>
          <div className="phone">{this.props.phone}</div>
          <div className="email">
            <a href={"mailto:" + this.props.email}>{this.props.email}</a>
          </div>
        </CardStyle>
      );
    } else {
      return (
        <CardStyle>
          <div className="images" onClick={this.props.onClick}>
            {this.getImage(this.props.photoBody)}
            {this.getImage(this.props.photoBody)}
            {this.getImage(this.props.photoBody)}
          </div>
          <div className="text">
            <div className="name">{this.props.name}</div>
            <div className="title">{this.props.title}</div>
            <div className="phone">{this.props.phone}</div>
            <div className="email">
              <a href={"mailto:" + this.props.email}>{this.props.email}</a>
            </div>
          </div>
        </CardStyle>
      );
    }
  }
}

class ExternalTeamDialog extends Component {
  getContacts() {
    let contacts = [];

    contacts.push(
      <div key="card" className="card">
        <ContactCard
          singleContact={true}
          photoBody={this.props.team.photoBody}
          name={this.props.team.name}
          title={this.props.team.title}
          phone={this.props.team.phone}
          email={this.props.team.email}
        />
      </div>,
    );

    this.props.responsibles.forEach((o) =>
      contacts.push(
        <div key={"card-" + o.name} className="card">
          <ContactCard
            singleContact={true}
            photoBody={o.photoBody}
            name={o.name}
            title={o.title}
            phone={o.phone}
            email={o.email}
          />
        </div>,
      ),
    );

    contacts.push(
      <div key={"card-support247"} className="card">
        <CardStyle>
          <div className="name">24/7/365 Support</div>
          <div className="description">
            For emergency customer support regarding domain name and DNS issues,
            outside regular office hours, phone support (+46317202097) is
            available within 30 minutes.
          </div>
          <div className="description">
            This emergency service is available from Monday to Friday between
            17:00 and 08:00 CET, as well as 24 hours on Saturday and Sunday.
          </div>
          <div className="description">
            The fee is EUR 200 startup fee and EUR 200 for every commenced hour.
          </div>
        </CardStyle>
      </div>,
    );

    return <div>{contacts}</div>;
  }

  getBody = () => {
    return (
      <StyledContainer fluid>
        <p>
          {" "}
          Don't hesitate to contact us and use our expertise within legal,
          digital and technical areas.
        </p>
        {this.getContacts()}
      </StyledContainer>
    );
  };

  render() {
    return (
      <DetailsDialog
        show={this.props.show}
        title="We are your team!"
        body={this.getBody()}
        onClose={() => this.props.onClose()}
        width="lg"
      />
    );
  }
}

class InternalTeamDialog extends Component {
  getBody = () => {
    return (
      <StyledContainer fluid>
        <InternalTeamPrefix>
          This page is an entry point for internal users for getting support
          from the development team of the Abion Core platform. Please read the
          bullet points below to find the suitable channel for your case.
        </InternalTeamPrefix>

        <InternalTeamBullet>
          If you <strong>have a question</strong> about Abion Core or Domas the
          first place to check is the{" "}
          <LinkContainer to="/help" onClick={this.props.onClose}>
            <a>Help Page</a>
          </LinkContainer>
          . There you will find video tutorials, guides and technical
          documentation.
        </InternalTeamBullet>
        <InternalTeamNoBullet>
          If you still can't find an answer to your question send an email to
          the development team at{" "}
          <a href="mailto:dev@abion.com">dev@abion.com</a>. Describe your
          question in detail and supply relevant screenshots, use the english
          language and write a separate email per issue. Don't include any other
          recipients in To or CC fields of the email, the email must be sent to
          the development team only. Note that the development team is not
          accessible to customers directly, don't include customers in the
          emails and don't request direct customer interactions.
        </InternalTeamNoBullet>

        <InternalTeamBullet>
          If you <b>have an idea</b> of new functionality or improvements to the
          platform, send an email to the Product Owner at{" "}
          <a href={"mailto:" + this.props.team.email}>
            {this.props.team.email}
          </a>
          . Try to include information helpful in determining the priority of
          the idea, such as what customers would benefit from it and how.
        </InternalTeamBullet>

        <InternalTeamBullet>
          If you want to <b>follow the development</b> of the platform please
          see the{" "}
          <LinkContainer to="/help" onClick={this.props.onClose}>
            <a>Help Page</a>
          </LinkContainer>
          . There you will find Release Notes describing recent new features and
          improvements as well as Development Boards that gives you an insight
          into upcoming features of the platform in the short term perspective.
        </InternalTeamBullet>
        <InternalTeamNoBullet>
          We also invite you to follow the development process{" "}
          <b>in detail and real time</b> by accessing our project management
          software directly. The address is{" "}
          <a
            href="http://jira.ports.local/secure/RapidBoard.jspa?rapidView=11"
            target="_blank"
          >
            jira.ports.local
          </a>{" "}
          and you login with abion/abion.
        </InternalTeamNoBullet>

        <InternalTeamBullet>
          For any other matters please contact development team at{" "}
          <a href="mailto:dev@abion.com">dev@abion.com</a>.
        </InternalTeamBullet>
      </StyledContainer>
    );
  };

  render() {
    return (
      <DetailsDialog
        show={this.props.show}
        title="Abion Core Platform Support"
        body={this.getBody()}
        onClose={() => this.props.onClose()}
        width="lg"
      />
    );
  }
}

function InternalTeamPrefix({ children }) {
  return (
    <Row className="section-description internal-team-prefix">
      <Col xs={12}>{children}</Col>
    </Row>
  );
}

function InternalTeamBullet({ children }) {
  return (
    <Row className="section-description internal-team-bullet">
      <Col xs={1}>
        <Icon color="primary" iconsize="40" prefix="fas" name="caret-right" />
      </Col>
      <Col xs={11}>{children}</Col>
    </Row>
  );
}

function InternalTeamNoBullet({ children }) {
  return (
    <Row className="section-description internal-team-no-bullet">
      <Col xs={1}></Col>
      <Col xs={11}>{children}</Col>
    </Row>
  );
}

export default ContactArea;
