import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { ActionButton } from "./Buttons";
import { ExternalLink } from "./Link";
import { FormatDateMonospace, FromByteArrayToBase64 } from "./Utils";
import { TableBase } from "./Tables";
import Colors from "./theme/Colors";
import fileDownload from "js-file-download";
import ChangeAccountDialog from "./ChangeAccountDialog";
import ReadOnlyForm from "./ReadOnlyForm";
import { DetailsDialog, DialogSection } from "./Dialog";

const StyledContainer = styled(Container)`
  .PARTIAL {
    color: ${Colors.warning};
  }

  .SUCCESS {
    color: ${Colors.success};
  }

  .FAILURE {
    color: ${Colors.error};
  }

  .PENDING,
  .NA {
    color: ${Colors.tertiary};
  }
`;

export default class TrademarkDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      documents: null,
      documentError: false,
      downloadingDocs: new Map(),
    };
  }

  componentDidMount() {
    this.reloadImage();
    this.reloadDocuments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.reloadImage();
      this.reloadDocuments();
    }
  }

  reloadDocuments = () => {
    this.setState({ documents: null, documentError: false });

    if (this.props.trademark?.links.documents) {
      this.props.session.backendGet(
        this.props.trademark.links.documents,
        (response) => {
          this.setState({ documents: response });
        },
        () => {
          this.setState({ documentError: true });
        },
      );
    }
  };

  reloadImage = () => {
    this.setState({ image: null });
    if (this.props.trademark?.links.image) {
      this.props.session.backendGetFile(
        this.props.trademark.links.image,
        (response) => {
          this.setState({ image: FromByteArrayToBase64(response) });
        },
      );
    }
  };

  getImage = () => {
    if (!this.state.image) {
      return;
    }
    return (
      <img
        key="image"
        src={"data:image/png;base64," + this.state.image}
        alt={""}
        style={{ "max-width": "40%", "margin-top": "10px" }}
      />
    );
  };

  getBasicInfo = (trademark) => {
    const content = [
      {
        label: "Type",
        value: trademark.productType,
      },
      {
        label: "Category",
        value: trademark.category,
      },
      {
        label: "Office",
        value: trademark.office,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Application Number",
        value: trademark.applicationNumber,
      },
      {
        label: "Registration Number",
        value: trademark.registrationNumber,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Application Date",
        value: FormatDateMonospace(trademark.applicationDate),
      },
      {
        label: "Registration Date",
        value: FormatDateMonospace(trademark.registrationDate),
      },
      {
        label: "Renewal Date",
        value: FormatDateMonospace(trademark.renewalDate),
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Applicant",
        value: trademark.applicantDetails,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Organisation",
        value: trademark.organisation,
      },
      {
        label: "Abion Case Number",
        value: trademark.caseNumber,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Comments",
        value: trademark.comments,
      },
    ];

    return (
      <DialogSection title="Basic Info">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  getClassDetails = (trademark) => {
    const classes = trademark.classes;
    if (!classes && classes.length < 1) {
      return;
    }

    const classColumns = [
      {
        name: "id",
        style: { width: "100px" },
      },
      {
        name: "text",
      },
    ];

    return [
      <DialogSection key="class-details" title="Class Details">
        <TableBase columns={classColumns} rows={classes} noTableHeader={true} />
      </DialogSection>,
    ];
  };

  getCountryDetails = (trademark) => {
    const countries = trademark.countries;
    if (countries === null || countries.length < 1) {
      return;
    }
    let countryDetails = [];
    countries.forEach((o) => {
      countryDetails.push(this.getCountryDetail(o));
    });

    return (
      <DialogSection title="Country Details">
        <div>{countryDetails}</div>
      </DialogSection>
    );
  };

  getCountryDetail = (country) => {
    let body = [];
    body.push([
      <DialogSection
        key={country.contry}
        title={country.country}
        className={country.progress}
      >
        <div>
          <b>Status: </b> {country.status}
          <br />
          <br />
        </div>
      </DialogSection>,
    ]);

    if (country.classDesignations && country.classDesignations.length > 0) {
      body.push(this.getCountryDesignations(country.classDesignations));
    }

    return body;
  };

  getClassText = (designation) => {
    if (designation.text === null || designation.text.trim().length === 0) {
      return "(Refused)";
    }
    return designation.text;
  };

  getCountryDesignations = (classDesignations) => {
    const columns = [
      {
        name: "id",
        style: { width: "100px" },
      },
      {
        name: "text",
        contentFunction: (r) => this.getClassText(r),
      },
    ];

    return [
      <Row key="altered-classes">
        <Col xs={12}>
          <div>
            <b>Altered Classes: </b>
            <br />
          </div>
        </Col>
      </Row>,
      <Row key="table">
        <Col xs={12}>
          <TableBase
            columns={columns}
            rows={classDesignations}
            noTableHeader={true}
          />
        </Col>
      </Row>,
    ];
  };

  getDocuments = () => {
    let parts = [];

    let loading = (
      <Row key="loading">
        <Col xs={12}>
          <pre>Loading, please wait</pre>
        </Col>
      </Row>
    );

    let error = (
      <Row key="error">
        <Col xs={12}>
          <pre>Failed fetching data. Please try again later.</pre>
        </Col>
      </Row>
    );

    if (this.state.documentError) {
      parts.push(error);
    } else if (this.state.documents === null) {
      parts.push(loading);
    } else if (this.state.documents.length > 0) {
      parts.push(this.getDocumentTable());
    } else {
      return;
    }

    return <DialogSection title="Documents">{parts}</DialogSection>;
  };

  getLink = (document) => {
    let icon = this.state.downloadingDocs.has(document.fileName)
      ? "spinner"
      : "";
    return (
      <ExternalLink
        onClick={() => this.onDownload(document)}
        tooltip={"Click to download"}
        text={document.description}
        className={"download-link"}
        icon={icon}
      />
    );
  };

  onDownload = (document) => {
    const url =
      this.props.trademark.links.documents +
      "/" +
      encodeURIComponent(document.fileName);
    const fileName = document.description + "." + document.extension;
    this.beforeDownload(document.fileName);
    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, fileName);
        this.afterDownload(document.fileName);
      },
      () => {
        this.props.window.showErrorFunc(
          "Document download failed. Please try again later",
        );
        this.afterDownload(document.fileName);
      },
    );
  };

  beforeDownload = (key) => {
    let docs = this.state.downloadingDocs;
    if (!docs.has(key)) {
      docs.set(key, null);
    }
    this.setState({ downloadingDocs: docs });
  };

  afterDownload = (key) => {
    let docs = this.state.downloadingDocs;
    docs.delete(key);
    this.setState({ downloadingDocs: docs });
  };

  getDocumentTable = () => {
    const columns = [
      {
        label: "Name",
        name: "description",
        contentFunction: (r) => this.getLink(r),
      },
      {
        label: "Created At",
        name: "date",
        style: { width: "150px" },
        contentFunction: (r) => FormatDateMonospace(r.date),
      },
    ];

    return <TableBase columns={columns} rows={this.state.documents} />;
  };

  showChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDialogShow: true,
    });
  };

  onClose = () => {
    this.props.onClose();
    this.setState({
      changeAccountDialogShow: false,
    });
  };

  onChangeAccount = () => {
    this.props.onChangeAccount();
    this.setState({
      changeAccountDialogShow: false,
    });
  };

  getBody = () => {
    let parts = [];
    if (this.props.trademark) {
      parts.push(this.getImage(this.props.trademark));
      parts.push(this.getBasicInfo(this.props.trademark));
      parts.push(this.getClassDetails(this.props.trademark));
      parts.push(this.getCountryDetails(this.props.trademark));
      parts.push(this.getDocuments(this.state.documents));
    }
    return <StyledContainer fluid={true}>{parts}</StyledContainer>;
  };

  getActionButtons() {
    let changeAccountButtonDisabled = true;
    let disabledChangeAccountTooltip;
    if (this.props.trademark) {
      changeAccountButtonDisabled =
        !this.props.trademark.sessionAccountChangeable;

      if (changeAccountButtonDisabled) {
        disabledChangeAccountTooltip =
          "Your user account does not have privileges to change account on the trademark, contact your client manager to activate this functionality";
      }
    }

    return (
      <>
        <div>
          <ActionButton
            key="change-account"
            text={"Change Account"}
            disabledTooltip={disabledChangeAccountTooltip}
            disabled={changeAccountButtonDisabled}
            onClick={() => this.showChangeAccountDialog()}
          />
        </div>
      </>
    );
  }

  render() {
    let title = "";
    if (this.props.trademark) {
      title = this.props.trademark.catchWord
        ? this.props.trademark.catchWord
        : this.props.trademark.productType;
    }

    return (
      <div>
        <DetailsDialog
          show={this.props.show}
          title={title}
          actions={this.getActionButtons()}
          body={this.getBody()}
          onClose={() => this.onClose()}
          width="lg"
          height="lg"
        />

        {this.state.changeAccountDialogShow && (
          <ChangeAccountDialog
            session={this.props.session}
            window={this.props.window}
            show={this.state.changeAccountDialogShow}
            errorMessage={this.state.errorMessage}
            submit={this.state.submit}
            changeAccountUrl={this.props.trademark.links.changeAccount}
            onClose={this.onClose}
            onChangeAccount={this.onChangeAccount}
          />
        )}
      </div>
    );
  }
}
